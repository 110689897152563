import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import loader from "./assets/img/loader/loading.svg";
import "./assets/css/styles.css";

const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const RequestScreen = lazy(() => import("./screens/RequestScreen"));
const ContactScreen = lazy(() => import("./screens/ContactScreen"));
const NoticeScreen = lazy(() => import("./screens/legals/NoticeScreen"));
const PrivacyScreen = lazy(() => import("./screens/legals/PrivacyScreen"));
const TermScreen = lazy(() => import("./screens/legals/TermScreen"));
const Error404Screen = lazy(() => import("./screens/Error404Screen"));

export default function Routes() {
  return (
    <Router>
      <Suspense
        fallback={
          <div className={"loader"}>
            <img src={loader} alt="Chargement..." />
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route path="/new" component={RequestScreen} />
          <Route path="/contact" component={ContactScreen} />
          <Route path="/legals/notice" component={NoticeScreen} />
          <Route path="/legals/privacy" component={PrivacyScreen} />
          <Route path="/legals/tos" component={TermScreen} />
          <Route component={Error404Screen} />
        </Switch>
      </Suspense>
    </Router>
  );
}
